<template>
    <ProfileCoach v-if="iscoach" :uid="uid"></ProfileCoach>
	<div v-else class="grid crud-demo">
		<div class="col-12">
			<div class="card">
                <Profile :uid="uid"></Profile>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import Profile from "@/components/Profile";
import ProfileCoach from "@/components/C_ProfileCoach";
import UserService from '../service/UserService';

export default {
    props: {
        uid: {
            default: -1
        }
    },
    components: {
        Profile,
        ProfileCoach
    },
	data() {
        return {
            iscoach: false
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
    },
    watch: {
      'uid': function (uid) {
        this.reload();
      }
    },
    mounted() {
        this.reload();
    },
	methods: {
        reload() {
            this.userService.getUser(this.uid).then(data => {
                this.iscoach = data['coach'];
            });
        }
    }
}
</script>

<style scoped lang="scss">
</style>
